import React, { useEffect, useMemo, useState } from "react";
import { uploadFile } from "../../../Services/index";
import { toast, ToastContainer } from "react-toastify";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import ConfirmationDialoge from "../../Common/ConfirmationDialoge";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import $ from "jquery";
import { activateAgent, addAgent, adminAgentResetPassword, agentChangeCommission, agentClaimBack, agentWalletSendOut, deactivateAgent, deleteAgent, getAgentDetails, reviveAdminAgent, reviveAgentPlayer, updateAgentDetails, walletWithdrawableBalance } from "../../../Services/MasterServices/PlayerService";
import { All_Agent_AgentTemplate_List_POST, All_Deleted_AgentTemplate_List_POST, All_Player_List_POST } from "../../../api/ApiConst";
import PaginationDataTable from "../../Common/PaginationDataTable";
import PageHeading from "../../Common/PageHeading";
import dateUtilities from "../../../utilities/DateUtilities";
import CustomButton from "../../Common/CustomButton";
import CheckboxButton from "../../Common/CheckboxButton";


const AgentMaster = () => {
    const { filterType } = useParams();
    const navigate = useNavigate();
    // const { state } = useLocation();
    const [show, setShow] = useState({
        addAgent: false,
        sendClaim: false,
        reward: false,
        withdrawableBalance: false,
        updateCommission: false,
        resetPassword: false,
    });
    const [amount, setAmount] = useState(0);
    const [commissionAmount, setCommissionAmount] = useState(1);
    const [updatePassword, setUpdatePassword] = useState("");
    const [remark, setRemark] = useState(null);
    const [rewardAmount, setRewardAmount] = useState(1);
    const [rewardRemark, setRewardRemark] = useState(null);
    const [drawCount, setDrawCount] = useState(0);
    const [gender, setGender] = useState(null);
    const [DOB, setDOB] = useState(null);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        isAuth: true,
        title: "",
        subTitle: "",
        severity: "",
    });
    const [bodyFormFileData, setbodyFormFileData] = useState("");
    const [assetsImageFormData, setAssetsImageFormData] = useState(null);
    const [viewDeletedList, setViewDeletedList] = useState(false);

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    const handleRefresh = () => setDrawCount((prev) => prev + 1);

    const handleSendClaimModal = () => setShow({ ...show, sendClaim: false });
    const handleCommissionModal = () => setShow({ ...show, updateCommission: false });
    const handleResetPasswordModal = () => setShow({ ...show, resetPassword: false });
    const handleWithdrawableBalanceModal = () => setShow({ ...show, withdrawableBalance: false });

    const handleAddPlayerModal = () => {
        setShow({ ...show, addAgent: false });
        handleClearForm();
    };

    const handleRewardModal = () => setShow({ ...show, reward: false });

    useEffect(() => {
        if (!show.reward) {
            setRewardRemark("");
        }
        if (!show.sendClaim) {
            setRemark("");
        }
    }, [show]);

    const handleNewPlayer = () => {
        setEdit(false);
        setShow({ ...show, addAgent: true });
    };

    const handleUserBasic = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
    };
    const handleGender = (e) => {
        setGender(e);
    };
    const [user, setUser] = useState({
        fullName: "",
        mobileNumber: "",
        // agentCode: "",
        email: "",
        isdCode: "+91",
        password: "",
        commissionPercentage: 0,
        photoImageUrl: "",
    });

    const genderOptions = [
        { value: "MALE", label: "Male", },
        { value: "FEMALE", label: "Female", },
        { value: "OTHER", label: "Other", },
    ];

    const handleDeletePlayer = (id) => {
        console.log(id);
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deleteAgent(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res.message);
            }
        });
    };
    const handleReviveData = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        reviveAdminAgent(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res.message);
            }
        });
    }
    const handleAgentDeactivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deactivateAgent(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res?.message);
            }
        });
    };
    const handleAgentActivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        activateAgent(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res?.message);
            }
        });
    };
    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };
    const handleCommission = (row) => {
        setId(row.uuid);
        setShow({
            ...show,
            updateCommission: true,
        });
        setCommissionAmount(row?.commissionPercentage)
    };
    const handleResetPassword = (row) => {
        setId(row.uuid);
        setShow({
            ...show,
            resetPassword: true,
        });
    };
    const handleUpdateWallet = (id) => {
        setId(id);
        setShow({
            ...show,
            sendClaim: true,
        });
    };
    const handleClaimBack = () => {
        let temp = {
            amount: amount,
            remark: remark,
        };
        if (remark === "") {
            toast.warn("🙁" + "Remarks Can`t be Empty", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        agentClaimBack(id, temp).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + amount + " Claimed Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
                handleSendClaimModal();
                setAmount(0);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res.message);
            }
        });
    };
    const handleSendOut = () => {
        let temp = {
            amount: amount,
            remark: remark,
        };
        if (remark === null) {
            toast.warn("🙁" + "Remarks Can`t be Empty", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        agentWalletSendOut(id, temp).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + amount + " Send Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
                handleSendClaimModal();
                setAmount(0);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res.message);
            }
        });
    };
    const handleUpdateCommission = () => {
        let temp = {
            commissionPercentage: commissionAmount,
        };
        if (temp.commissionPercentage === "") {
            toast.warn("🙁" + "Commission Percentage Can`t be Empty", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        agentChangeCommission(id, temp).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" +  commissionAmount + " Send Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
                handleCommissionModal();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res.message);
            }
        });
    };
    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };
    const columns = useMemo(
        () => [{
            id: "status",
            name: "",
            selector: (row) => {
                if (row.active) {
                    return <div className="led-green" title="Active"></div>;
                } else {
                    return <div className="led-red" title="Not Active"></div>;
                }
            },
            width: "60px",
            sortable: false,
        }, {
            id: "image",
            selector: (row, index) => {
                return <span className="profile-img">{row?.photoImageUrl ? <img src={row?.photoImageUrl} alt={"User"} /> : <img src="/assets/images/user.png" alt={"User"} />}</span>;
            },
            width: "100px",
            sortable: false,
        }, {
            name: "Name",
            selector: (row) => (row.fullName && row.fullName !== "" ? row.fullName : row.nickName),
            sortable: true,
            wrap: true,
            width: "150px",
        }, {
            name: "Mobile",
            selector: (row) => (
                <Link to={"/agentDetail?rId=" + row.uuid} key="t-adminUserDetail">
                    {row.isdCode + " " + row.mobile}
                </Link>
            ),
            sortable: true,
        }, {
            name: "Agent Code",
            selector: (row) => row.referralCode,
            sortable: true,
            width: "140px",
            wrap: true
        }, {
            name: "Commission %",
            selector: (row) => <> {row.commissionPercentage + " %"}</>,
            sortable: true,
            right: true,
            width: "180px",
            wrap: true
        }, {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            width: "220px",
            wrap: true
        }, {
            name: "Wallet Balance",
            selector: (row) => <>{process.env.REACT_APP_CURRENCY_SYMBOL} {row.walletBalance.toFixed(2)}</>,
            sortable: true,
            right: true,
            width: "140px",
            wrap: true
        }, {
            name: "Withdrawable Balance",
            selector: (row) => <>{process.env.REACT_APP_CURRENCY_SYMBOL} {row.withdrawableBalance.toFixed(2)}</>,
            sortable: true,
            right: true,
            width: "180px",
            wrap: true
        }, {
            name: "Registered At",
            selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy hh:mm aa"),
            sortable: true,
            // right: true,
            width: "160px",
            wrap: true
        }, {
            name: "Actions",

            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                        <KeyboardArrowDownOutlinedIcon />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <li onClick={() => handleEditPlayer(row.uuid)}>
                            <button className="dropdown-item" type="button">
                                Edit
                            </button>
                        </li>
                        { }
                        {!viewDeletedList ? <li
                            onClick={() =>
                                setConfirmDialog({
                                    isOpen: true,
                                    title: "Are Your Sure? Your want to delete this record",
                                    onConfirm: () => handleDeletePlayer(row.uuid),
                                })
                            }
                        >
                            <button className="dropdown-item" type="button">
                                Delete
                            </button>
                        </li> : <li
                            onClick={() =>
                                setConfirmDialog({
                                    isOpen: true,
                                    title: "Are Your Sure? Your want to Revive this record",
                                    onConfirm: () => handleReviveData(row.uuid),
                                })
                            }
                        >
                            <button className="dropdown-item" type="button">
                                Revive
                            </button>
                        </li>
                        }
                        {row.active ? (
                            <li
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are Your Sure? Your want to Deactivate this player",
                                        onConfirm: () => handleAgentDeactivate(row.uuid),
                                    })
                                }
                            >
                                <button className="dropdown-item" type="button">
                                    Deactivate
                                </button>
                            </li>
                        ) : (
                            <li
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are Your Sure? Your want to Activate this player",
                                        onConfirm: () => handleAgentActivate(row.uuid),
                                    })
                                }
                            >
                                <button className="dropdown-item" type="button">
                                    Activate
                                </button>
                            </li>
                        )}
                        <li onClick={() => handleUpdateWallet(row.uuid)}>
                            <button className="dropdown-item" type="button">
                                Send/Claim
                            </button>
                        </li>
                        <li onClick={() => handleCommission(row)}>
                            <button className="dropdown-item" type="button">
                                Commission
                            </button>
                        </li>
                        <li onClick={() => handleResetPassword(row)}>
                            <button className="dropdown-item" type="button">
                                Reset Password
                            </button>
                        </li>
                    </ul>
                </div>
            ),
            sortable: true,
            right: true,
        }],
        [drawCount, viewDeletedList]
    );
    const handleImageUpload = (e) => {
        var input = e.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (ev) {
                $("#assetImageImg").attr("src", ev.target.result);
                setAssetsImageFormData(ev.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
        let image = e.target.files[0];
        let bodyFormData = new FormData();
        bodyFormData.append("file", image);
        setbodyFormFileData(bodyFormData);
    };
    const validateAddUpdateAssets = () => {
        let inputTag = $("#formrow-inputZip").val();
        if (!(inputTag != "" || ($("#imageId").val() != "" && $("#assetsImageInput").val() != ""))) {
            toast.warn("Please select image first.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;
        }
        return true;
    };
    const handleAddPlayer = (e) => {
        e.preventDefault();
        if (validateAddUpdateAssets(e)) {
            let inputTag = $("#formrow-inputZip").val();
            if (inputTag != "") {
                uploadFile(bodyFormFileData).then((res) => {
                    if (res.errorCode === 0) {
                        $("#assetsImageInput").val(res.responsePacket);
                        submitForm();
                    }
                });
            } else {
                submitForm();
            }
        }
    };
    const submitForm = () => {
        const timestamp = new Date(DOB).getTime();
        let request = {
            fullName: user?.fullName,
            // agentCode: user?.agentCode,
            email: user?.email,
            password: user?.password,
            isdCode: user?.isdCode,
            mobileNumber: user?.mobileNumber,
            gender: gender?.value,
            commissionPercentage: user?.commissionPercentage,
            dateOfBirthTimeStamp: timestamp,
            photoImageUrl: $("#assetsImageInput").val(),
        };
        if (request.fullName === "" || request.fullName === null) {
            handleWarningToast("Please provide full name name.");
            return;
        } else if (request.commissionPercentage === "" || request.commissionPercentage === null) {
            handleWarningToast("Please provide Commission Percentage.");
            return;
        } else if (request.password === "" || request.password === null) {
            handleWarningToast("Please provide password.");
            return;
        } else if (request.isdCode === "" || request.isdCode === null) {
            handleWarningToast("Please provide ISD code.");
            return;
        } else if (request.email === "" || request.email === null) {
            handleWarningToast("Please provide Email .");
            return;
        } else if (request.mobileNumber === null || request.mobileNumber.length === 0) {
            handleWarningToast("Mobile Number is Required.");
            return;
        } else if (request.mobileNumber.length < 8) {
            handleWarningToast("Please provide valid mobile number.");
            return;
        } else if (request.gender === undefined || request.gender === null) {
            handleWarningToast("Please Select Agent Gender !");
            return;
        } else if (request.dateOfBirthTimeStamp === undefined || request.dateOfBirthTimeStamp === null) {
            handleWarningToast("Please Select Date Of Birth !");
            return;
        }
        if ($("#imageId").val() != "") {
            updateAgentDetails(id, request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddPlayerModal();
                    handleClearForm();
                } else {
                    handleFailerToast(res?.message);
                }
            });
        } else {

            addAgent(request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddPlayerModal();
                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    handleFailerToast(res?.message);
                }
            });
        }
    };
    const handleEditPlayer = (id) => {
        setId(id);
        setShow({ ...show, addAgent: true });
        setEdit(true);
        getAgentDetails(id).then((res) => {
            if (res.errorCode === 0) {
                let userDetails = res.responsePacket;
                const getDate = dateUtilities.formattedDate(userDetails?.dateOfBirth, "yyyy-MM-dd");
                let dict = {
                    fullName: userDetails.fullName,
                    email: userDetails?.email,
                    password: userDetails?.password,
                    isdCode: userDetails?.isdCode,
                    commissionPercentage: userDetails?.commissionPercentage,
                    mobileNumber: userDetails.mobileNumber,
                    photoImageUrl: userDetails.profileImageUrl
                };
                setUser(dict);
                setDOB(getDate);
                let d = {
                    value: userDetails.gender,
                    label: userDetails.gender,
                };
                setGender(d);
                $("#imageId").val(res.responsePacket.recordId);

                $("#assetsImageInput").val(userDetails.profileImageUrl);
                // setSelectedImage(userDetails.profileImageUrl)

            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };
    const handleWarningToast = (data) => {
        toast.warn(data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleFailerToast = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleClearForm = () => {
        setUser({
            fullName: "",
            mobileNumber: "",
            profileImageURL: "",
        });
        setGender("");
        setDOB(new Date());
        // setSelectedImage(null)
    };
    const sendClaimModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.sendClaim} onHide={() => handleSendClaimModal()} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>Send And Claim</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleSendClaimModal()}></button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Enter Amount
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="formrow-email-input"
                                        placeholder="Enter Amount"
                                        min={1}
                                        value={amount}
                                        step={0.01}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Enter Remarks
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="formrow-email-input"
                                        placeholder="Enter Remarks"
                                        value={remark}
                                        onChange={(e) => setRemark(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className="btn btn-primary w-md" onClick={handleSendOut}>
                                Send Out
                            </button>
                            &nbsp; &nbsp;
                            <button onClick={handleClaimBack} className="btn btn-danger w-md">
                                Claim Back
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    const addPlayerModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.addAgent} onHide={() => handleAddPlayerModal()} size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>{edit ? "Edit Agent" : "Add Agent"}</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleAddPlayerModal()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Full Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter Full Name "
                                            name="fullName"
                                            value={user.fullName}
                                            onChange={(e) => handleUserBasic(e)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter Email"
                                            name="email"
                                            value={user.email}
                                            onChange={(e) => handleUserBasic(e)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="formrow-password-input" className="form-label">
                                            Upload Image
                                        </label>
                                        <input type="hidden" id="imageId" />
                                        <input type="hidden" id="assetsImageInput" />
                                        <img
                                            src={user.photoImageUrl != "" ? user.photoImageUrl : "https://th.bing.com/th/id/OIP.vx0QOVHGpMq7Furxtzu6KgHaHa?pid=ImgDet&amp;rs=1"}
                                            id="assetImageImg"
                                            style={{
                                                height: "130px",
                                                width: "100%",
                                                objectFit: "contain",
                                                border: "1px solid #7c7979",
                                                padding: "10px",
                                            }}
                                        />
                                        <input type="file" className="form-control" accept="image/*" id="formrow-inputZip" onChange={(e) => handleImageUpload(e)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Commission %
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter commission Percentage"
                                            name="commissionPercentage"
                                            value={user.commissionPercentage}
                                            onChange={(e) => handleUserBasic(e)}
                                        />
                                    </div>
                                    {edit ? "" :
                                        <div className="mb-3">
                                            <label htmlFor="formrow-email-input" className="form-label">
                                                Password *
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="formrow-email-input"
                                                placeholder="Password"
                                                name="password"
                                                value={user.password}
                                                onChange={(e) => handleUserBasic(e)}
                                            />
                                        </div>
                                    }
                                    <div className="d-flex justify-content-between ">
                                        <div className="col-4">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-email-input" className="form-label">
                                                    ISD Code
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-email-input"
                                                    placeholder="Enter ISD"
                                                    name="isdCode"
                                                    value={user.isdCode}
                                                    onChange={(e) => handleUserBasic(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-email-input" className="form-label">
                                                    Enter Mobile Number
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="formrow-email-input"
                                                    placeholder="Enter Mobile Number"
                                                    name="mobileNumber"
                                                    value={user.mobileNumber}
                                                    onChange={(e) => handleUserBasic(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between ">
                                        <div className="col-4">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Select Gender
                                                </label>
                                                <Select options={genderOptions} value={gender} onChange={(e) => handleGender(e)} />
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Select DOB
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="formrow-password-input"
                                                    placeholder="Enter DOB"
                                                    onChange={(e) => setDOB(e.target.value)}
                                                    value={DOB}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">

                                </div>

                                <div className="col-md-6 ">

                                </div>


                            </div>
                            <div>
                                <a className="btn btn-primary w-md" onClick={handleAddPlayer}>
                                    {edit ? "Update Agent" : "Add Agent"}
                                </a>
                                &nbsp; &nbsp;
                                <a onClick={() => handleAddPlayerModal()} className="btn btn-primary w-md">
                                    Cancel
                                </a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    const handleChangeCommission = () => {
        return (<Modal className="tablerowmodal" show={show.updateCommission} onHide={() => handleCommissionModal()} size="md" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body className="">
                <div className="modal-header border-bottom-0">
                    <h4>Update Commission</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleCommissionModal()}></button>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="formrow-email-input" className="form-label">
                                    Enter Commission %
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="formrow-email-input"
                                    placeholder="Enter Commission"
                                    // min={2}
                                    value={commissionAmount}
                                    // step={0.01}
                                    onChange={(e) => setCommissionAmount(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className="btn btn-primary w-md" onClick={handleUpdateCommission}>
                            Update Commission
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        )
    }
    const resetPasswordModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.resetPassword} onHide={() => handleResetPasswordModal()} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    {console.log(show)}
                    <div className="modal-header border-bottom-0">
                        <h4>Update Password</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleResetPasswordModal()}></button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Enter New Password
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="formrow-email-input"
                                        placeholder="Enter Password"
                                        value={updatePassword}
                                        onChange={(e) => setUpdatePassword(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className="btn btn-primary w-md" onClick={handleUpdatePassword}>
                                Update Password
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    const handleUpdatePassword = () => {
        let temp = {
            password: updatePassword,
        };
        // return;
        if (remark === null) {
            toast.warn("🙁" + "Remarks Can`t be Empty", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        adminAgentResetPassword(id, temp).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + "Password Reset Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
                handleResetPasswordModal();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res.message);
            }
        });
    };
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading="Agent List" />
                    <div className="card">
                        <div className="d-flex justify-content-end" style={{ position: "absolute", top: "22px", left: "35px", zIndex: 100 }}>
                            <div style={{ width: "200px" }}>
                                <CustomButton handleClick={handleNewPlayer} title="Add Agent" iconClass="bx bx-plus" buttonClass="createButton" />
                            </div>
                            <div style={{ width: "200px" }}>

                            </div>
                        </div>
                        <div className="card-body">
                            <PaginationDataTable
                                paginationUrl={viewDeletedList ? All_Deleted_AgentTemplate_List_POST : All_Agent_AgentTemplate_List_POST}
                                serverSidePagination={true}
                                search={true}
                                columns={columns}
                                additionalClassName={"agentSearchInput"}
                            // filter={{ kycVerificationStatus: filter.value }}
                            />
                            <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                        </div>
                    </div>
                </div>
            </div>
            {show.sendClaim && sendClaimModal()}
            {/* {show.withdrawableBalance && withdrawableBalanceModal()} */}
            {/* {show.reward && rewardModal()} */}
            {show.updateCommission && handleChangeCommission()}
            {show.resetPassword && resetPasswordModal()}
            {show.addAgent && addPlayerModal()}
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default AgentMaster;
