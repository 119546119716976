import React, { useEffect, useRef, useState } from 'react'
import Select from "react-select"
import { enGB } from 'date-fns/locale'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import PageHeading from './Common/PageHeading'
import { toast, ToastContainer } from "react-toastify";
import dateUtilities from '../utilities/DateUtilities'
import { All_Number_Of_Depositing_Player_Count_POST, All_Number_Of_Game_Played_Count_POST, All_Registered_Player_Count_POST } from '../api/ApiConst'
import { callPostApi } from '../api/ApiCaller'
import Chart from 'react-apexcharts'
import moduleUtilities from '../utilities/ModuleUtils';

const intervalKeyValueOptions = [
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Yearly", label: "Yearly" },
]

const AdminReportMaster = ({ moduleList }) => {
    const [selectedInterval, setSelectedInterval] = useState(intervalKeyValueOptions[0]);
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const dailyReportDayCount = 7;
    const [timeObj, setTimeObj] = useState({
        fromDateTimeStamp: dateUtilities.timestampToIst(new Date().getTime()) - dailyReportDayCount * (24 * 60 * 60 * 1000),
        toDateTimeStamp: dateUtilities.timestampToIst(new Date().getTime()),
    });

    const adjustTime = (date, hours, minutes) => {
        const adjustedDate = new Date(date);
        adjustedDate.setHours(adjustedDate.getHours() - hours);
        adjustedDate.setMinutes(adjustedDate.getMinutes() - minutes);
        return adjustedDate;
    };

    const [dateRange, setDateRange] = useState([{
        startDate: adjustTime(new Date(timeObj.fromDateTimeStamp), 5, 30),
        endDate: adjustTime(new Date(timeObj.toDateTimeStamp), 5, 30),
        key: 'selection'
    }]);

    const [registeredPlayerCount, setRegisteredPlayerCount] = useState(null);
    const [depositPlayerCount, setDepositPlayerCount] = useState(null);
    const [gamePlayedCount, setGamePlayedCount] = useState(null);

    const getRegisteredPlayerCount = () => {
        callPostApi(All_Registered_Player_Count_POST.replaceAll("{reportType}", selectedInterval.value), timeObj, {}, (response) => {
            let allDates = null;
            if (selectedInterval.value === "Daily") {
                allDates = calculateMissingDates(timeObj.fromDateTimeStamp, timeObj.toDateTimeStamp);
                response.responsePacket.map((item, counter) => {
                    allDates.set(item.date, {
                        "count": item.count
                    });
                });
            } else if (selectedInterval.value === "Weekly") {
                allDates = calculateMissingWeeks(timeObj.fromDateTimeStamp, timeObj.toDateTimeStamp);
                response.responsePacket.map((item, counter) => {
                    allDates.set("Week " + item.week, {
                        "count": item.count
                    });
                });
            } else if (selectedInterval.value === "Monthly") {
                allDates = calculateMissingMonths(timeObj.fromDateTimeStamp, timeObj.toDateTimeStamp);
                response.responsePacket.map((item, counter) => {
                    allDates.set(item.month + "-" + item.year, {
                        "count": item.count
                    });
                });
            } else if (selectedInterval.value === "Yearly") {
                allDates = calculateMissingYears(timeObj.fromDateTimeStamp, timeObj.toDateTimeStamp);
                response.responsePacket.map((item, counter) => {
                    allDates.set(item.year + "", {
                        "count": item.count
                    });
                });
            }
            let finalData = [];
            for (let key of allDates.keys()) {
                finalData.push({
                    "date": key,
                    "count": (allDates.get(key).count !== undefined ? allDates.get(key).count : 0)
                });
            }
            setRegisteredPlayerCount(finalData);
        }, (error) => { });
    }

    const getDepositCount = () => {
        callPostApi(All_Number_Of_Depositing_Player_Count_POST.replaceAll("{reportType}", selectedInterval.value), timeObj, {}, (response) => {
            let allDates = null;
            if (selectedInterval.value === "Daily") {
                allDates = calculateMissingDates(timeObj.fromDateTimeStamp, timeObj.toDateTimeStamp);
                response.responsePacket.map((item, counter) => {
                    allDates.set(item.date, {
                        "count": item.count
                    });
                });
            } else if (selectedInterval.value === "Weekly") {
                allDates = calculateMissingWeeks(timeObj.fromDateTimeStamp, timeObj.toDateTimeStamp);
                response.responsePacket.map((item, counter) => {
                    allDates.set("Week " + item.week, {
                        "count": item.count
                    });
                });
            } else if (selectedInterval.value === "Monthly") {
                allDates = calculateMissingMonths(timeObj.fromDateTimeStamp, timeObj.toDateTimeStamp);
                response.responsePacket.map((item, counter) => {
                    allDates.set(item.month + "-" + item.year, {
                        "count": item.count
                    });
                });
            } else if (selectedInterval.value === "Yearly") {
                allDates = calculateMissingYears(timeObj.fromDateTimeStamp, timeObj.toDateTimeStamp);
                response.responsePacket.map((item, counter) => {
                    allDates.set(item.year + "", {
                        "count": item.count
                    });
                });
            }
            let finalData = [];
            for (let key of allDates.keys()) {
                finalData.push({
                    "date": key,
                    "count": (allDates.get(key).count !== undefined ? allDates.get(key).count : 0)
                });
            }
            setDepositPlayerCount(finalData);
        }, (error) => { });
    }

    const getGamePlayedCount = () => {
        const fromDateIst = dateUtilities.timestampToIst(new Date(dateRange[0]?.startDate.toDateString()).getTime());
        const toDateIst = dateUtilities.timestampToIst(new Date(dateRange[0]?.endDate.toDateString()).getTime());
        const intervalData = {
            fromDateTimeStamp: fromDateIst,
            toDateTimeStamp: toDateIst,
        };
        callPostApi(All_Number_Of_Game_Played_Count_POST.replaceAll("{reportType}", selectedInterval.value), intervalData, {}, (response) => {
            let allDates = null;
            if (selectedInterval.value === "Daily") {
                allDates = calculateMissingDates(timeObj.fromDateTimeStamp, timeObj.toDateTimeStamp);
                response.responsePacket.map((item, counter) => {
                    allDates.set(item.date, {
                        "playerCount": item.playerCount,
                        "gameCount": item.gameCount,
                        "gameCount_1_INR" : item.gameCount_1_INR,
                        "gameCount_10_INR" : item.gameCount_10_INR,
                        "gameCount_100_INR" : item.gameCount_100_INR,
                        "gameCount_1000_INR" : item.gameCount_1000_INR
                    });
                });
            } else if (selectedInterval.value === "Weekly") {
                allDates = calculateMissingWeeks(timeObj.fromDateTimeStamp, timeObj.toDateTimeStamp);
                response.responsePacket.map((item, counter) => {
                    allDates.set("Week " + item.week, {
                        "playerCount": item.playerCount,
                        "gameCount": item.gameCount,
                        "gameCount_1_INR" : item.gameCount_1_INR,
                        "gameCount_10_INR" : item.gameCount_10_INR,
                        "gameCount_100_INR" : item.gameCount_100_INR,
                        "gameCount_1000_INR" : item.gameCount_1000_INR
                    });
                });
            } else if (selectedInterval.value === "Monthly") {
                allDates = calculateMissingMonths(timeObj.fromDateTimeStamp, timeObj.toDateTimeStamp);
                response.responsePacket.map((item, counter) => {
                    allDates.set(item.month + "-" + item.year, {
                        "playerCount": item.playerCount,
                        "gameCount": item.gameCount,
                        "gameCount_1_INR" : item.gameCount_1_INR,
                        "gameCount_10_INR" : item.gameCount_10_INR,
                        "gameCount_100_INR" : item.gameCount_100_INR,
                        "gameCount_1000_INR" : item.gameCount_1000_INR
                    });
                });
            } else if (selectedInterval.value === "Yearly") {
                allDates = calculateMissingYears(timeObj.fromDateTimeStamp, timeObj.toDateTimeStamp);
                response.responsePacket.map((item, counter) => {
                    allDates.set(item.year + "", {
                        "playerCount": item.playerCount,
                        "gameCount": item.gameCount,
                        "gameCount_1_INR" : item.gameCount_1_INR,
                        "gameCount_10_INR" : item.gameCount_10_INR,
                        "gameCount_100_INR" : item.gameCount_100_INR,
                        "gameCount_1000_INR" : item.gameCount_1000_INR
                    });
                });
            }
            let finalData = [];
            for (let key of allDates.keys()) {
                finalData.push({
                    "date": key,
                    "playerCount": (allDates.get(key).playerCount !== undefined ? allDates.get(key).playerCount : 0),
                    "gameCount": (allDates.get(key).gameCount !== undefined ? allDates.get(key).gameCount : 0),
                    "gameCount_1_INR" : (allDates.get(key).gameCount_1_INR !== undefined ? allDates.get(key).gameCount_1_INR : 0),
                    "gameCount_10_INR" : (allDates.get(key).gameCount_10_INR !== undefined ? allDates.get(key).gameCount_10_INR : 0),
                    "gameCount_100_INR" : (allDates.get(key).gameCount_100_INR !== undefined ? allDates.get(key).gameCount_100_INR : 0),
                    "gameCount_1000_INR" : (allDates.get(key).gameCount_1_INR !== undefined ? allDates.get(key).gameCount_1000_INR : 0)
                });
            }
            setGamePlayedCount(finalData);
        }, (error) => { });
    }

    const dateRangePickerRef = useRef(null);
    const dateRangeValidation = {
        // minAllowedDays: 3 - 1,
        maxAllowedDays: 31 - 1,
        // minAllowedWeeksInDays: 7 - 1,
        maxAllowedWeeksInDays: 84 - 1,
        // minAllowedMonthInDays: 30 - 1,
        maxAllowedMonthInDays: 365 - 1,
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            const dateRangePicker = document.querySelector('.rdrDateRangeWrapper');
            if (dateRangePicker && !dateRangePicker.contains(event.target)) {
                setShowDateRangePicker(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const [dailyActiveUsers, setDailyActiveUsers] = useState({
        fromDateTimeStamp: new Date().getTime() - dailyReportDayCount * (24 * 60 * 60 * 1000),
        toDateTimeStamp: new Date().getTime(),
    });

    const [dailyActiveUsersCount, setDailyActiveUsersCount] = useState(null);

    const getDailyActiveUsersCount = () => {
        if (!moduleUtilities.checkModuleIsEnable(moduleList, "LudoModule")) {
            return;
        }
        callPostApi(All_Number_Of_Game_Played_Count_POST.replaceAll("{reportType}", "Daily"), timeObj, {}, (response) => {
            const allDates = calculateMissingDates(dailyActiveUsers.fromDateTimeStamp, dailyActiveUsers.toDateTimeStamp);
            response.responsePacket.map((item, counter) => {
                allDates.set(item.date, {
                    "playerCount": item.playerCount,
                    "gameCount": item.gameCount
                });
            });
            let finalData = [];
            for (let key of allDates.keys()) {
                finalData.push({
                    "date": key,
                    "playerCount": (allDates.get(key).playerCount !== undefined ? allDates.get(key).playerCount : 0),
                    "gameCount": (allDates.get(key).gameCount !== undefined ? allDates.get(key).gameCount : 0),
                });
            }
            setDailyActiveUsersCount(finalData);
        }, (error) => { });
    }

    const [weeklyActiveUser, setWeeklyActiveUser] = useState({
        fromDateTimeStamp: new Date().getTime() - 49 * (24 * 60 * 60 * 1000),
        toDateTimeStamp: new Date().getTime(),
    });
    const [weeklyActiveUsersCount, setWeeklyActiveUsersCount] = useState(null);
    const getWeeklyActiveUsersCount = () => {
        if (!moduleUtilities.checkModuleIsEnable(moduleList, "LudoModule")) {
            return;
        }
        callPostApi(All_Number_Of_Game_Played_Count_POST.replaceAll("{reportType}", "Weekly"), timeObj, {}, (response) => {
            const allWeeks = calculateMissingWeeks(weeklyActiveUser?.fromDateTimeStamp, weeklyActiveUser?.toDateTimeStamp);
            response.responsePacket.map((item, counter) => {
                allWeeks.set("Week " + item.week, {
                    "playerCount": item.playerCount,
                    "gameCount": item.gameCount
                });
            });
            let finalData = [];
            for (let key of allWeeks.keys()) {
                finalData.push({
                    "date": key,
                    "playerCount": (allWeeks.get(key).playerCount !== undefined ? allWeeks.get(key).playerCount : 0),
                    "gameCount": (allWeeks.get(key).gameCount !== undefined ? allWeeks.get(key).gameCount : 0),
                });
            }
            setWeeklyActiveUsersCount(finalData);
        }, (error) => { }
        );
    }

    const calculateMissingDates = (fromTimestamp, toTimestamp) => {
        const allDatesMap = new Map();
        const fromDate = new Date(fromTimestamp);
        const toDate = new Date(toTimestamp);
        // Generate an array of date strings within the specified range
        while (fromDate <= toDate) {
            const dateString = fromDate.toISOString().split('T')[0];
            allDatesMap.set(dateString, {});
            fromDate.setDate(fromDate.getDate() + 1);
        }
        return allDatesMap;
    };
    const calculateMissingWeeks = (fromTimestamp, toTimestamp) => {
        const weekMap = new Map();
        const currentDate = new Date(fromTimestamp);
        const toDate = new Date(toTimestamp);

        while (currentDate <= toDate) {
            const isoWeekNumber = getISOWeekNumber(currentDate);
            const year = currentDate.getFullYear();
            const weekKey = "Week " + isoWeekNumber;
            if (!weekMap.has(weekKey)) {
                weekMap.set(weekKey, {});
                currentDate.setDate(currentDate.getDate() + 7);
            }
        }
        return weekMap;
    };
    const getISOWeekNumber = (date) => {
        const tempDate = new Date(date);
        tempDate.setHours(0, 0, 0, 0);
        tempDate.setDate(tempDate.getDate() + 4 - (tempDate.getDay() || 7));
        const yearStart = new Date(tempDate.getFullYear(), 0, 1);
        return Math.ceil((((tempDate - yearStart) / 86400000) + 1) / 7);
    };

    const calculateMissingMonths = (fromTimestamp, toTimestamp) => {
        const allMonthMap = new Map();
        const fromDate = new Date(fromTimestamp);
        const toDate = new Date(toTimestamp);
        // Generate an array of date strings within the specified range
        while (fromDate <= toDate) {
            const monthYearKey = `${fromDate.toLocaleString('default', { month: 'long' })}-${fromDate.getFullYear()}`;
            allMonthMap.set(monthYearKey, {});
            fromDate.setMonth(fromDate.getMonth() + 1);
        }
        return allMonthMap;
    };

    const calculateMissingYears = (fromTimestamp, toTimestamp) => {
        const allMonthMap = new Map();
        const fromDate = new Date(fromTimestamp);
        const toDate = new Date(toTimestamp);
        // Generate an array of date strings within the specified range
        while (fromDate <= toDate) {
            const monthYearKey = fromDate.getFullYear() + "";
            allMonthMap.set(monthYearKey, {});
            fromDate.setFullYear(fromDate.getFullYear() + 1);
        }
        return allMonthMap;
    };

    useEffect(() => {
        if (moduleUtilities.checkModuleIsEnable(moduleList, "LudoModule") === true) {
            getWeeklyActiveUsersCount();
            getDailyActiveUsersCount();
            getGamePlayedCount();
        }
    }, [dailyActiveUsers, weeklyActiveUser, moduleList])


    useEffect(() => {
        getRegisteredPlayerCount();
        getDepositCount();
        if (moduleUtilities.checkModuleIsEnable(moduleList, "LudoModule") === true) {
            getGamePlayedCount();
        }
    }, [timeObj]);

    const handleDateRange = (ranges) => {
        setDateRange([ranges?.selection]);
    }

    const handleIntervalChanges = (interval) => {
        setSelectedInterval(interval)
    }
    const handleDays = (ranges) => {
        // setDateRange([{
        //     startDate: new Date(),
        //     endDate: new Date(),
        //     key: 'selection',
        // },])
        if (Array.isArray(ranges)) {
            setDateRange(ranges);
        }
        setShowDateRangePicker(!showDateRangePicker);
    };
    const handleSubmit = () => {
        const fromDate = new Date(dateRange[0]?.startDate.toDateString()).getTime();
        const toDate = new Date(dateRange[0]?.endDate.toDateString()).getTime();
        const fromDateIst = dateUtilities.timestampToIst(fromDate);
        const toDateIst = dateUtilities.timestampToIst(toDate);
        const intervalData = {
            fromDateTimeStamp: fromDateIst,
            toDateTimeStamp: toDateIst,
        };
        if (dateRange[0]?.startDate.getTime() !== dateRange[0]?.endDate.getTime()) {
            // Calculate the difference in days
            const dayDifference = Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24));
            if (selectedInterval.value === "Daily" && (dayDifference > dateRangeValidation.maxAllowedDays)) {
                toast.warn("Please select max 31 days", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    id: "toast",
                });
                return;
            }
            if (selectedInterval.value === "Weekly" && (dayDifference > dateRangeValidation.maxAllowedWeeksInDays)) {
                toast.error("Please select max 84 days", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    id: "toast",
                });
                return;
            }
            if (selectedInterval.value === "Monthly" && (dayDifference > dateRangeValidation.maxAllowedMonthInDays)) {
                toast.warn("Please select max 12 month.", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    id: "toast",
                });
                return;
            }
            if (selectedInterval.value === "Yearly" && dayDifference < dateRangeValidation.maxAllowedMonthInDays) {
                toast.warn("Please select correct Date.", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    id: "toast",
                });
                return;
            }
            setTimeObj(intervalData);
            setShowDateRangePicker(false);
        }
        setTimeObj(intervalData);
    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <PageHeading heading={"Report"} />
                    <div className="position-relative d-flex justify-content-between ">
                        <Select
                            options={intervalKeyValueOptions}
                            value={selectedInterval}
                            onChange={(e) => { handleIntervalChanges(e); }}
                            className="customSelect"
                        />
                        <button className='btn  border-2  border-dark bg-white mx-2' onClick={handleDays} >
                            {dateUtilities.formattedDate(dateRange[0]?.startDate, "dd-MM-yyyy")} &nbsp; --  &nbsp;{
                                dateUtilities.formattedDate(dateRange[0]?.endDate, "dd-MM-yyyy")}
                        </button>
                        <button className=' bg-primary text-white border-0  px-3 rounded-2 '
                            onClick={handleSubmit}
                        >Submit</button>
                    </div>
                    <div className='customDatePiker'>
                        <div className=' position-relative '>
                            {showDateRangePicker && (
                                <>
                                    <DateRangePicker
                                        ref={dateRangePickerRef}
                                        className=''
                                        ranges={dateRange}
                                        onChange={(ranges) => {
                                            handleDateRange(ranges);
                                        }}
                                        locale={enGB}
                                        style={{ top: '100px', left: '50px' }}
                                    >
                                    </DateRangePicker>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        {registeredPlayerCount !== null &&
                            <Chart type='area'
                                height={350}
                                series={[{
                                    name: "Number of Players Registered",
                                    data: registeredPlayerCount.map(item => ({ x: (item.date), y: item.count }))
                                }]}
                                options={{
                                    chart: {
                                        toolbar:
                                        {
                                            show: true,
                                            tools: {
                                                download: true,
                                                selection: true,
                                                zoom: false,
                                                zoomin: false,
                                                zoomout: false,
                                                pan: false,
                                                reset: false | '<img src="/static/icons/reset.png" width="20">',
                                                customIcons: []
                                            },
                                            export: {
                                                csv: {
                                                    filename: "Registered Player Count",
                                                    columnDelimiter: ',',
                                                    headerCategory: 'Date',
                                                    headerValue: 'value',
                                                    dateFormatter(timestamp) {
                                                        return new Date(timestamp).toDateString()
                                                    }
                                                },
                                            },
                                        },
                                    },
                                    title: { text: 'Registered Player Count' }
                                }}
                            />
                        }
                    </div>
                    <div className='col-6'>
                        {depositPlayerCount !== null &&
                            <Chart type='area'
                                height={350}
                                series={[{
                                    name: "Number of Deposits",
                                    data: depositPlayerCount.map(item => ({ x: (item.date), y: item.count }))
                                }]}
                                options={{
                                    chart: {
                                        toolbar:
                                        {
                                            show: true,
                                            tools: {
                                                download: true,
                                                selection: true,
                                                zoom: false,
                                                zoomin: false,
                                                zoomout: false,
                                                pan: false,
                                                reset: false | '<img src="/static/icons/reset.png" width="20">',
                                                customIcons: []
                                            },
                                            export: {
                                                csv: {
                                                    filename: "Deposit Count",
                                                    columnDelimiter: ',',
                                                    headerCategory: 'Date',
                                                    headerValue: 'value',
                                                    dateFormatter(timestamp) {
                                                        return new Date(timestamp).toDateString()
                                                    }
                                                },
                                            },
                                        },
                                    },
                                    title: { text: 'Deposit Count' }
                                }}
                            />
                        }
                    </div>
                    {moduleUtilities.checkModuleIsEnable(moduleList, "LudoModule") &&
                        <div className='col-12'>
                            {gamePlayedCount !== null &&
                                <Chart type='area'
                                    height={350}
                                    series={[
                                        {
                                            name: "Unique Player",
                                            data: gamePlayedCount.map(item => ({ x: (item.date), y: item.playerCount }))
                                        },
                                        {
                                            name: "Number of Game Played",
                                            data: gamePlayedCount.map(item => ({ x: (item.date), y: item.gameCount }))
                                        },
                                        {
                                            name: "1 INR Games",
                                            data: gamePlayedCount.map(item => ({ x: (item.date), y: item.gameCount_1_INR }))
                                        },
                                        {
                                            name: "10 INR Games",
                                            data: gamePlayedCount.map(item => ({ x: (item.date), y: item.gameCount_10_INR }))
                                        },
                                        {
                                            name: "100 INR Games",
                                            data: gamePlayedCount.map(item => ({ x: (item.date), y: item.gameCount_100_INR}))
                                        },
                                        {
                                            name: "1000 INR Games",
                                            data: gamePlayedCount.map(item => ({ x: (item.date), y: item.gameCount_1000_INR }))
                                        }
                                    ]}
                                    options={{
                                        chart: {
                                            toolbar:
                                            {
                                                show: true,
                                                tools: {
                                                    download: true,
                                                    selection: true,
                                                    zoom: false,
                                                    zoomin: false,
                                                    zoomout: false,
                                                    pan: false,
                                                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                                                    customIcons: []
                                                },
                                                export: {
                                                    csv: {
                                                        filename: "Number of Games Played (Ludo)",
                                                        columnDelimiter: ',',
                                                        headerCategory: 'Date',
                                                        headerValue: 'value',
                                                        dateFormatter(timestamp) {
                                                            return new Date(timestamp).toDateString()
                                                        }
                                                    },
                                                },
                                            },
                                        },
                                        title: { text: 'Number of Games Played (Ludo)' }
                                    }}
                                />
                            }
                        </div>
                    }
                    {/* {moduleUtilities.checkModuleIsEnable(moduleList, "LudoModule") &&
                        <div className='col-12 mt-4'>
                            <div className='row'>
                                <div className='col-6'>
                                    {dailyActiveUsersCount !== null &&
                                        <Chart type='area'
                                            height={350}
                                            series={[{
                                                name: "Unique Player",
                                                data: dailyActiveUsersCount.map(item => ({ x: (item.date), y: item.playerCount }))
                                            }, {
                                                name: "Number of Game Played",
                                                data: dailyActiveUsersCount.map(item => ({ x: (item.date), y: item.gameCount }))
                                            }
                                        ]}
                                            options={{
                                                chart: {
                                                    toolbar:
                                                    {
                                                        show: true,
                                                        tools: {
                                                            download: true,
                                                            selection: true,
                                                            zoom: false,
                                                            zoomin: false,
                                                            zoomout: false,
                                                            pan: false,
                                                            reset: false | '<img src="/static/icons/reset.png" width="20">',
                                                            customIcons: []
                                                        },
                                                        export: {
                                                            csv: {
                                                                filename: "Daily Game Played Ludo",
                                                                columnDelimiter: ',',
                                                                headerCategory: 'Date',
                                                                headerValue: 'value',
                                                                dateFormatter(timestamp) {
                                                                    return new Date(timestamp).toDateString()
                                                                }
                                                            },
                                                        },
                                                    },
                                                },
                                                title: { text: 'Daily Game Played ( Ludo )' }
                                            }}
                                        />
                                    }
                                </div>

                                <div className='col-6'>
                                    {weeklyActiveUsersCount !== null &&
                                        <Chart type='area'
                                            height={350}
                                            series={[
                                                {
                                                    name: "Unique Player",
                                                    data: weeklyActiveUsersCount.map(item => ({ x: (item.date), y: item.playerCount }))
                                                },
                                                {
                                                    name: "Number of Game Played",
                                                    data: weeklyActiveUsersCount.map(item => ({ x: (item.date), y: item.gameCount }))
                                                }]}
                                            options={{
                                                chart: {
                                                    toolbar:
                                                    {
                                                        show: true,
                                                        tools: {
                                                            download: true,
                                                            selection: true,
                                                            zoom: false,
                                                            zoomin: false,
                                                            zoomout: false,
                                                            pan: false,
                                                            reset: false | '<img src="/static/icons/reset.png" width="20">',
                                                            customIcons: []
                                                        },
                                                        export: {
                                                            csv: {
                                                                filename: "Weekly Game Played Ludo",
                                                                columnDelimiter: ',',
                                                                headerCategory: 'Week',
                                                                headerValue: `${dateUtilities.formattedDate(weeklyActiveUsersCount[0]?.fromDateTimeStamp, "dd-MMM-yyyy")} to ${dateUtilities.formattedDate(weeklyActiveUsersCount[0]?.toDateTimeStamp, "dd-MMM-yyyy")}`,
                                                                dateFormatter(timestamp) {
                                                                    return new Date(timestamp).toDateString()
                                                                },
                                                                
                                                            },
                                                        },
                                                    },
                                                },
                                                title: { text: 'Weekly Game Played ( Ludo )' }
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    } */}
                </div>
            </div>
        </div>
    )
}
export default AdminReportMaster